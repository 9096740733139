// in src/MyMenu.js

import { Menu } from 'react-admin';
import ListIcon from '@mui/icons-material/List';

export const MyMenu = () => (
    <Menu>
        <Menu.DashboardItem />
        <Menu.Item to="/medical_cases" primaryText="component.title.medical_cases" leftIcon={<ListIcon />}/>
        <Menu.Item to="/groups" primaryText="component.title.groups" leftIcon={<ListIcon />}/>
        <Menu.Item to="/medical_advices" primaryText="component.title.medical_advices" leftIcon={<ListIcon />}/>
        <Menu.Item to="/general_practitioners" primaryText="component.title.general_practitioners" leftIcon={<ListIcon />}/>
        <Menu.Item to="/locations" primaryText="component.title.locations" leftIcon={<ListIcon />}/>
    </Menu>
);