import { TextField, List, Datagrid, EditButton, ShowButton } from "react-admin";

export const LocationList = props => (
    <List {...props}>
        <Datagrid>
            <TextField source={"name"} label="component.header.name" />
            <TextField source={"address"} label="component.header.address" />
            <TextField source={"postcode"} label="component.header.postcode" />
            <TextField source={"city"} label="component.header.city" />
            <TextField source={"operationCapacity"} label="component.header.operation_capacity" />
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
);