

const url = process.env.REACT_APP_API_URL;

var authProvider = {
    login: ({ username, password }) => {
        const request = new Request(url + '/authentication_token', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                localStorage.setItem('token', auth.token);
            })
            .catch(() => {
                throw new Error('Ongeldige referenties.')
            });
    },
    // when the dataProvider returns an error, check if this is an authentication error
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    // when the token navigates, make sure that their credentials are still valid
    checkAuth: () => localStorage.getItem('token')
        ? Promise.resolve()
        : Promise.reject(),
    // remove local credentials and notify the auth server that the token logged out
    logout: () => {
        localStorage.removeItem('token');
        return Promise.resolve();
    },
    // get the token's profile
    getIdentity: () => {
        try {
            return Promise.resolve();
        } catch (error) {
            return Promise.reject(error);
        }
    },
    // get the token permissions (optional)
    getPermissions: () => Promise.resolve(),
};


export default authProvider;