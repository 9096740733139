import {
   ReferenceArrayInput, SelectArrayInput
} from "react-admin";
import { InputGuesser } from "@api-platform/admin";

export const MedicalAdviceForm = () => {
    return (
        <>
            <InputGuesser source={"name"} label="component.header.name" />
            <InputGuesser source={"label"} label="component.header.label" />
            <ReferenceArrayInput source="medicalCases" reference="medical_cases" label="component.header.medical_cases">
                <SelectArrayInput optionText="id" />
            </ReferenceArrayInput>
        </>
    )
}
