// in src/Dashboard.js
import * as React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Title } from 'react-admin';
export default () => (

    <Card>
        <Title title="Welcome by Phizi" />
        <CardContent>
            Dashboard
        </CardContent>
  </Card>
);

/*
    <Card>
        <Title title="Welcome by Phizi" />
        <CardContent>Lorem ipsum sic dolor amet...</CardContent>
        <CardContent>Lorem ipsum sic dolor amet...</CardContent>
    </Card>*/