import { DateField } from "../Fields/DateField";
import { AgeYearField } from "../Fields/AgeYearField";
import { ReferenceField, ExportButton, FilterForm, ListBase, TextInput, CreateButton, FilterButton, TextField, Datagrid, Pagination, EditButton, ShowButton, FunctionField } from "react-admin";
// import { useRecordContext } from 'react-admin';
import { Stack } from '@mui/material';
// const PurpleTextField = ({ source }) => {
//     const record = useRecordContext();
//     return (<span style={{ color: 'purple' }}>{record && record[source]}</span>);
// };

const medicalCaseFilter = [
    <TextInput label="component.header.search" source="generalPractinioner.firstName" alwaysOn />,
    <TextInput label="component.header.group_name" source="entityGroup.name" />,
    <TextInput label="component.title.locations" source="location.name" />,
];

const ListToolbar = () => (
    <Stack direction="row" justifyContent="space-between">
        <FilterForm filters={medicalCaseFilter}  />
            <FilterButton filters={medicalCaseFilter} style={{ 'margin-top': '18px', 'min-width' : '170px' }} />
            <CreateButton style={{ 'padding-right': '35px'}} />
            <ExportButton />
    </Stack>
)

const MCPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50]} />;

export const MedicalCaseList = props => (
    <ListBase {...props}>
        <ListToolbar />
        <Datagrid>
            <DateField source={"date"} label="component.header.date" />
            <TextField source={"caseNumber"} label="component.header.case_number" />
            <TextField source={"gender"} label="component.header.gender" />
            <TextField source={"ageKnown"} label="component.header.age_known" />
            <AgeYearField source={"ageYear"} label="component.header.age_year" />
            <FunctionField
                label="component.header.group_name"
                sortBy="entityGroup.name"
                render={record => `${record.groupName}`}
            />
            <FunctionField
                label="component.header.general_prac_name"
                sortBy="generalPractinioner.firstName"
                render={record => `${record.generalPractinionerName}`}
            />
            <TextField source={"differentialDiagnosisMs"} label="component.header.general_diagnosis_ms" />
            <ReferenceField source={"medicalAdvice"} label="component.header.medical_advice" link={false} reference="medical_advice">
                <TextField source="label" />
            </ReferenceField>
            <ShowButton />
            <EditButton />
        </Datagrid>
        <MCPagination />
    </ListBase>
);
