/* eslint-disable import/no-anonymous-default-export */
import { Navigate, Route } from "react-router-dom";
import { useState } from "react";
import { HydraAdmin, hydraDataProvider as baseHydraDataProvider, ResourceGuesser, fetchHydra as baseFetchHydra, useIntrospection } from "@api-platform/admin";
import { parseHydraDocumentation } from "@api-platform/api-doc-parser";
import { defaultTheme, resolveBrowserLocale } from 'react-admin';
import { CustomRoutes } from "react-admin";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { nl } from './i18n/nl';
import Dashboard from "./Dashboard";
import authProvider from './Auth/authProvider';

import { MyLayout } from './MyLayout';
import { MedicalCaseCreate } from './Forms/MedicalCaseCreate';
import { MedicalCaseEdit } from './Forms/MedicalCaseEdit';
import { MedicalAdviceCreate } from './Forms/MedicalAdviceCreate';
import { MedicalAdviceEdit } from './Forms/MedicalAdviceEdit';
import { GeneralPractitionerCreate } from './Forms/GeneralPractitionerCreate';
import { GeneralPractitionerEdit } from "./Forms/GeneralPractitionerEdit";
import { MedicalCaseList } from "./Lists/MedicalCaseList";
import { GroupList } from "./Lists/GroupList";
import { GPList } from "./Lists/GPList";
import { LocationList } from "./Lists/LocationList";
import { MedicalAdviceList } from "./Lists/MedicalAdviceList";
import { MedicalAdviceShow } from "./Show/MedicalAdviceShow";


const i18nProvider = polyglotI18nProvider(
    locale => (locale === 'nl' ? nl : nl),
    //locale => {},
    resolveBrowserLocale(),
    [
        { locale: 'nl', name: 'Dutch' },
    ],
);

const theme = {
    ...defaultTheme,
    sidebar: {
        width: 300, // The default value is 240
        closedWidth: 70, // The default value is 55
    },
};

const url = process.env.REACT_APP_API_URL + '/api';
const getHeaders = () => localStorage.getItem("token") ? {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
} : {};

const fetchHydra = (url, options = {}) =>
    baseFetchHydra(url, {
        ...options,
        headers: getHeaders,
    });

const RedirectToLogin = () => {
    const introspect = useIntrospection();

    if (localStorage.getItem("token")) {
        introspect();
        return <></>;
    }
    return <Navigate to="/login" />;
};

const apiDocumentationParser = (setRedirectToLogin) => async () => {
    try {
        setRedirectToLogin(false);
        return await parseHydraDocumentation(url, { headers: getHeaders });
    } catch (result) {
        const { api, response, status } = result;
        if (status !== 401 || !response) {
            throw result;
        }
        localStorage.removeItem("token");
        setRedirectToLogin(true);

        return {
            api,
            response,
            status,
        };
    }
};

const dataProvider = (setRedirectToLogin) => baseHydraDataProvider({
    entrypoint: url,
    httpClient: fetchHydra,
    apiDocumentationParser: apiDocumentationParser(setRedirectToLogin),
});

const Admin = () => {
    const [redirectToLogin, setRedirectToLogin] = useState(false);

    return (
        <>
            <HydraAdmin dashboard={Dashboard} layout={MyLayout} i18nProvider={i18nProvider} dataProvider={dataProvider(setRedirectToLogin)} theme={theme} entrypoint={url} requireAuth authProvider={authProvider}>
                <CustomRoutes>
                    {redirectToLogin ? <Route path="/" element={<RedirectToLogin />} /> : null}
                </CustomRoutes>
                <ResourceGuesser name={"medical_cases"} options={{ label: "component.title.medical_cases" }} list={MedicalCaseList} create={MedicalCaseCreate} edit={MedicalCaseEdit} />
                <ResourceGuesser name={"groups"} options={{ label: "component.title.groups" }} list={GroupList} />
                <ResourceGuesser name={"medical_advices"} options={{ label: "component.title.medical_advices" }} list={MedicalAdviceList} show={MedicalAdviceShow} create={MedicalAdviceCreate} edit={MedicalAdviceEdit}  />
                <ResourceGuesser name={"general_practitioners"} options={{ label: "component.title.general_practitioners" }} list={GPList} create={GeneralPractitionerCreate} edit={GeneralPractitionerEdit} />
                <ResourceGuesser name={"locations"} options={{ label: "component.title.locations" }} list={LocationList} />
            </HydraAdmin>
        </>
    );
}
export default Admin;

/*
*
    <ResourceGuesser name={"medical_cases"} list={MedicalCaseList} create={MedicalCaseCreate}  />

<ResourceGuesser name={"groups"} />
<ResourceGuesser name={"locations"} />
<ResourceGuesser name={"medical_advices"} />
<ResourceGuesser name={"general_practitioners"} />
<ResourceGuesser name={"medical_cases"} />
    <ResourceGuesser name={"medical_advices"} />*/

/*

<HydraAdmin requireAuth disableTelemetry theme={defaultTheme} i18nProvider={i18nProvider} entrypoint={url} >
  <ResourceGuesser name={"medical_cases"} list={MedicalCaseList} create={MedicalCaseCreate}  />
  <ResourceGuesser name={"groups"} />
  <ResourceGuesser name={"locations"} />
  <ResourceGuesser name={"medical_advices"} />
  <ResourceGuesser name={"general_practitioners"} />
</HydraAdmin>
*/
