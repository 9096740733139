
import dutchMessages from 'ra-language-dutch';

export const nl = {
    ...dutchMessages,
    component: {
        title: {
            groups: "Groepen",
            medical_cases: "Medische case",
            medical_advices: "Medisch advies",
            general_practitioners: "Huisartsen",
            locations: "Locaties",
        },
        header: {
            date: "Datum",
            created_at: "Aangemaakt op",
            name: "Naam",
            gender: "Geslacht",
            age_known: "Leeftijd bekend",
            age_year: "Geboortejaar",
            group_name: "Groep",
            general_prac_name: "Huisarts",
            general_diagnosis_ms: "Differtieele diagnose MS",
            medical_advice: "Medisch advies",
            first_name: "Voornaam",
            last_name: "Achternaam",
            type: "Type",
            full_name: "Volledige naam",
            address: "Adres",
            postcode: "Postcode",
            city: "Stad",
            operation_capacity: "Operatie capaciteit",
            medical_cases: "Medische case",
            label: "Label",
            case_number: "Zaaknummer",
            search: "Zoeken"
        }
    },
};