import { TextField, List, Datagrid, EditButton, ShowButton } from "react-admin";


export const GroupList = props => (
    <List {...props}>
        <Datagrid>
            <TextField source={"name"} label="component.header.name" />
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
);