import { TextField, DateField, ReferenceArrayField, List, Datagrid, SingleFieldList, ChipField, ShowButton, EditButton } from "react-admin";

export const MedicalAdviceList = props => (
    <List {...props}>
        <Datagrid>
            <TextField source={"name"} label="component.header.name" />
            <TextField source={"label"} label="component.header.label" />
            <ReferenceArrayField label="component.header.medical_cases" reference="medical_cases"  link={false} source="medicalCases" >
                <SingleFieldList linkType="edit">
                    <ChipField source="caseId" />
                </SingleFieldList>
            </ReferenceArrayField>
            <DateField source={"createdAt"} label="component.header.created_at" />
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
);