import { TextField, List, Datagrid, ShowButton, EditButton, FunctionField } from "react-admin";

export const GPList = props => (
    <List {...props}>
        <Datagrid>
            <TextField source={"firstName"} label="component.header.first_name" />
            <TextField source={"lastName"} label="component.header.last_name" />
            <TextField source={"type"} label="component.header.type" />
            <FunctionField
                label="component.header.full_name"
                // sortBy="firstName"
                render={record => `${record.fullName}`}
            />
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
  );