import { ShowGuesser, FieldGuesser} from "@api-platform/admin";
import { ReferenceArrayField, SingleFieldList, ChipField} from "react-admin";

export const MedicalAdviceShow = props => (
    <ShowGuesser {...props}>
        <FieldGuesser source="name" />
        <FieldGuesser source="label" />
        <ReferenceArrayField label="component.header.medical_cases" reference="medical_cases" link={false} source="medicalCases" >
            <SingleFieldList linkType="edit">
                <ChipField source="caseId" />
            </SingleFieldList>
        </ReferenceArrayField>
        <FieldGuesser source="createdAt" label="component.header.created_at" />
    </ShowGuesser>
);
