import { useRecordContext } from 'react-admin';

export const DateField = props => {
    const record = useRecordContext(props);
    console.log(record)
    const date = new Date(record.date)
    const year = date.getFullYear()
    const day = date.getDate()
    const month = date.getMonth()+1 //Javascript a month start with 0, so have todo +1

    const fullDate = `${day}-${month}-${year}`
    return record ? <div style={{width: 70}}>{fullDate}</div> : null;
}

DateField.defaultProsp = { label: 'Date' }
