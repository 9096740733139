import {RadioButtonGroupInput} from "react-admin";
import { InputGuesser, CreateGuesser } from "@api-platform/admin";

export const GeneralPractitionerCreate = props => (
    <CreateGuesser {...props}>
        <RadioButtonGroupInput source={"type"}  label="Type"  choices={[
            { id: 'Huisarts', name: 'Huisarts' },
            { id: 'Huisarts in opleiding', name: 'Huisarts in opleiding' },
            { id: 'Physician assistant (PA)', name: 'Physician assistant (PA)' },
            { id: 'Nurse practitioner (NP)', name: 'Nurse practitioner (NP)' },
            { id: 'Praktijk ondersteuner', name: 'Praktijk ondersteuner' },
            { id: 'Waarnemend huisarts', name: 'Waarnemend huisarts' }
        ]} />
        <InputGuesser source={"firstName"} />
        <InputGuesser source={"lastName"} />
    </CreateGuesser>
);