import { useRecordContext } from 'react-admin';
import {InputGuesser} from "@api-platform/admin";

export const AgeYearField = props => {
    const record = useRecordContext(props);
    //return <InputGuesser source={"ageYear"} label="4. Geboortejaar" sx={{width: "30vw"}}/>
    return  <div style={{width: 60}}>{record.ageYear}</div> 
}

AgeYearField.defaultProsp = { label: 'Age year' }
