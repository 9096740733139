import {
    AutocompleteInput,
    BooleanInput,
    DateInput,
    RadioButtonGroupInput,
    ReferenceInput,
    TextInput
} from "react-admin";
import { AgeYearField } from "../Fields/AgeYearField";
import {InputGuesser} from "@api-platform/admin";

export const MedicalCaseForm = () => {
    return (
        <>
            <h1>Medische case</h1>
            <InputGuesser source={"caseNumber"} label="Case nummer" />
            <DateInput source={"date"} defaultValue={new Date()} label="1. Datum"/>
            <RadioButtonGroupInput source={"gender"} label="2. Geslacht" choices={[
                {id: 'Male', name: 'Man'},
                {id: 'Female', name: 'Vrouw'},
                {id: 'Other', name: 'Overig'},
            ]}/>

            <RadioButtonGroupInput source={"ageKnown"} label="3. Leeftijd bekend?" choices={[
                {id: 1, name: 'Ja'},
                {id: 0, name: 'Nee'}
            ]}/>
            <InputGuesser source={"ageYear"}
                            format={v => {
                                return new Date().getFullYear() - v;
                            }}
                            defaultValue={new Date().getFullYear()}
                            parse={v => {
                                const calculatedYear = new Date().getFullYear() - parseInt(v);
                                return calculatedYear;
                            }} label="4. Leeftijd" sx={{width: "30vw"}}/>
            <ReferenceInput
                source={"entityGroup"}
                reference="groups"
                label="5. App group"
                filterToQuery={searchText => ({name: searchText})}
                perPage={2000}
                sort={{ field: 'name', order: 'ASC' }}
            >
                <AutocompleteInput optionText="name" label="5. App group" sx={{width: "30vw"}}/>
            </ReferenceInput>

            <ReferenceInput
                source={"location"}
                reference="locations"
                label="6. Location"
                filterToQuery={searchText => ({name: searchText})}
                perPage={2000}
                sort={{ field: 'name', order: 'ASC' }}
            >
                <AutocompleteInput optionText="name" label="6. Location" sx={{width: "30vw"}}/>
            </ReferenceInput>

            <ReferenceInput
                source={"generalPractinioner"}
                reference="general_practitioners"
                label="7. Huisarts"
                filterToQuery={searchText => ({name: searchText})}
                perPage={2000}
                sort={{ field: 'firstName', order: 'ASC' }}
            >
                <AutocompleteInput label="7. Huisarts" optionText="fullName" sx={{width: "30vw"}}
                />
            </ReferenceInput>

            <TextInput multiline={true} label={"8. Verwijsvraag"} source={"referralQuestion"}/>
            <InputGuesser source={"differentialDiagnosisMs"} label="9. Differentieele diagnose door MS indien gegeven"
                          sx={{width: "30vw"}}/>

            <div><b>Na triage wordt door de Medische Specialist (MS) meestal een waarschijnlijkheidsdiagnose
                gegeven.</b></div>
            <ReferenceInput
                source={"medicalAdvice"}
                reference="medical_advices"
                label="Groups"
                filterToQuery={searchText => ({name: searchText})}
                perPage={2000}
                sort={{ field: 'name', order: 'ASC' }}
            >
                <RadioButtonGroupInput label="Wat is het advies" source={"medicalAdvice"} row={false}/>
            </ReferenceInput>

            <TextInput multiline={true} type={"text"} label="Omschrijving" source={"medicalAdviceDescription"}/>

            <label>
                10. Is patient daadwerkelijk geopereerd
                <InputGuesser source={"patientHadOperation"}/>
            </label>

            <fieldset>
                <legend>Operatie</legend>
                <ReferenceInput
                    source={"operationLocation"}
                    reference="locations"
                    label="6. Huisarts"
                    filterToQuery={searchText => ({name: searchText})}
                    perPage={2000}
                    sort={{ field: 'name', order: 'ASC' }}
                >
                    <AutocompleteInput label="Locatie" optionText="name" sx={{width: "30vw"}}
                    />
                </ReferenceInput>

                <DateInput parse={v => {
                                if (v.length === 0) {
                                    return null;
                                }
                                
                                return v;
                            }}
                            source={"operationDate"}
                            defaultValue={null}
                            label=" "
                            sx={{width: "30vw"}}/>
                <br></br>
                <TextInput multiline={true} label={""} source={"operationDescription"} sx={{width: "30vw"}}/>
            </fieldset>

            <BooleanInput label="11. Complicatie" source={"hadComplication"}/>
            <fieldset>
                <legend>Complicatie</legend>
                <DateInput parse={v => {
                                if (v.length === 0) {
                                    return null;
                                }
                                
                                return v;
                            }} source={"complicationDate"} defaultValue={null} label=" " sx={{width: "30vw"}}/>
                <br></br>
                <TextInput multiline={true} source={"complicationDescription"} sx={{width: "30vw"}}/>
            </fieldset>
        </>
    )
}
